.slick-next {
    right: 33px !important;
}

.slick-prev {
    left: 33px !important;
    z-index: 55;
}

.banner {
    width: 100%;
    height: 250px;
}

.text-div {
    text-align: center;
    margin-top: 40px;
    padding: 0 1rem;
    h1 {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #cf3427;
    }
    h4 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 18px;
        line-height: 1.2;
    }
    p {
        color: #6d6d6d;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 3rem;
    }
}

.winner_cap {
    background-image: url(https://ipl2024.abccargo.ae/frontend/img/4.jpg);
    background-size: cover;
    padding: 52px 20px 30px;
    min-height: 300px;
}

.person_grid {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 44%) 0px 3px 8px;
}

.person_grid:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(38, 38, 38);
    opacity: 0.1;
    left: 0;
    top: 0;
}

.predict_grid_head {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
}

.predictperson h2 {
    font-weight: 700;
    z-index: 1;
    position: relative;
    font-size: 17px;
    background-color: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: inline-block;
    padding: 8px 13% 12px;
    /* border-top: 1px solid; */
    color: #ff660e;
}

.person_grid input,
.person_grid select {
    width: 60%;
    z-index: 9;
    position: relative;
    height: 50px;
    background-color: rgb(255 255 255 / 100%);
    border: 0;
    border-radius: 5px;
    padding: 10px;
    margin-top: 65px !important;
    text-align: left;
    text-transform: capitalize;
}

.person_grid input[type="submit"] {
    background-color: #ffffff;
    /* border: 1px solid #838282; */
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 30px;
    margin-top: 20px !important;
    color: black;
    border-radius: 50px;
    width: 60%;
    color: #e9602e;
    text-align: center;
}

.todays_match {
    background-image: url(https://ipl2024.abccargo.ae/frontend/img/about-1-copyright.jpg);
    background-size: cover;
    padding: 40px 15px;
    position: relative;
    margin-top: 20px;
    h1 {
        z-index: 1;
        position: relative;
        color: white;
        font-weight: 700;
    }
}

.para {
    color: white;
    position: relative;
    z-index: 1;
}

.todays_match:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #7e0303db;
    z-index: 0;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
    .slick-next {
        right: -20px !important;
    }
    .slick-prev {
        left: -20px !important;
        z-index: 55;
    }
}

.text-center {
    text-align: center !important;
}

.team_match_grid {
    border: 1px solid #ccc;
    padding: 20px 10px;
    margin-top: 20px;
    background-color: white;
    border-radius: 10px;
    min-height: 315px;
    h3 {
        font-size: 17px;
        padding: 0 10px;
        margin-bottom: 10px;
        min-height: 50px;
    }
}

.instruction {
    font-size: 12px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 10px;
}

.team {
    width: 30%;
    text-align: center;
    h5 {
        font-size: 16px;
    }
    p {
        font-size: 14px;
        color: #818181;
    }
}

.team picture {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 5px solid #ccc;
    object-fit: cover;
    height: 100%;
    display: block;
    overflow: hidden;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.team_radio {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
}

.team_radio input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.game_time {
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 11px;
    border-radius: 5px;
    h6 {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 600px) and (min-width: 301px) {
    .person_grid input,
    .person_grid select {
        width: 100% !important;
    }
    .person_grid input[type="submit"] {
        width: 100% !important;
    }
    .team_match_grid {
        h3 {
            font-size: 15px;
            padding: 0 10px;
            margin-bottom: 10px;
            min-height: 0;
        }
    }
    .team_radio {
        width: 90px;
        height: 90px;
    }
}