.logo {
    width: 120px;
    margin-left: 1rem;
}

.footer {
    text-align: center;
    padding: 1rem;
    background-color: #E8E8E8;
}

.footer_para {
    font-size: 14px;
}